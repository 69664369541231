import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../sections/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

import HeroText from '../sections/HeroText/HeroText';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import FormLoader from '../components/FormLoader/FormLoader';
import entryAnimation from '../resources/entry-animation';
import fadeInElementAnimation from "../resources/fade-in-element-animation";

const InfoTiles = ({ data }) => (

    <div className="info-section">
        {data.map((item) => {
            const tileRef = useRef();
            fadeInElementAnimation([tileRef])
            return (
                <div ref={tileRef} className="info-tile" key={item.header}>
                    <div className="info-tile__icon">
                        <img src={item.icon[0].localFile.publicURL} alt="Obrazek nawiązujący do tytułu kafelki"/>
                    </div>
                    <div className="info-tile__text">
                        <h3>
                            {item.header}
                        </h3>

                        <span>{item.description}</span>

                    </div>
                </div>
            )
        })}
    </div>
);

const RaportTikTokPage = () => {
    const data = useStaticQuery(query);
    const mainSettings = data.allGetHeroMainSettings.nodes[0];
    const reportTiktokData = data.allTiktokReportPage.nodes[0];
    const navbarBreakpointRef = useRef();
    const scriptUrl = 'https://r5c8k9e7pe.execute-api.us-east-1.amazonaws.com/'
        + 'prod-aws/flotiq-forms/tiktok_report_registartions-0_HCDUlew';

    const [isLoaded, setIsLoaded] = useState(false);

    const handleOnLoad = () => {
        setIsLoaded(true);
    };

    const mainRef = useRef(null);
    entryAnimation(mainRef);

    const handleScriptInject = ({ scriptTags }) => {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = handleOnLoad;
        }
    };
    const headerRef = useRef()
    const mainSectionRef = useRef();
    const emailRef = useRef()
    fadeInElementAnimation([headerRef, mainSectionRef, emailRef], '90')
    return (
        <main ref={mainRef} className="raport-tiktok">
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
                logo={mainSettings.logo[0] ? mainSettings.logo[0].localFile.publicURL : ''}
                smallLogo={mainSettings.small_logo[0] ? mainSettings.small_logo[0].localFile.publicURL : ''}
            />
            <Helmet
                onChangeClientState={(newState, addedTags) => handleScriptInject(addedTags)}
            >

                <title>Raport TikTok | GetHero</title>
                <link rel="stylesheet" href="https://flotiq-form-generator-styles.s3.amazonaws.com/styles.css" />
                <script src={scriptUrl} async />

            </Helmet>
            <HeroText
                header={reportTiktokData.header}
                firstParagraph={reportTiktokData.first_hero_paragraph}
                secondParagraph={reportTiktokData.second_hero_paragraph}
                buttonText1="Formularz"
                buttonLink1="#tiktok_form"
            />

            <div ref={headerRef} className="header-container">
                <h4 className="tiktok-header">
                    {reportTiktokData.subheader}
                </h4>
            </div>
            <div ref={mainSectionRef} className="row" id="arrow-down-position">
                <div className="col-lg-6 raport-tiktok__right-col">
                    <div id="tiktok_form" className="tiktok-registration">
                        <div className="tiktok-registration__form">
                            <span className="required-info">*Obowiązkowe</span>
                            { isLoaded
                                ? (<flotiq-form />)
                                : (<FormLoader />)}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 raport-tiktok__left-col">

                    {reportTiktokData.image[0].localFile && (
                        <div className="image-container">
                            <img
                                className="img"
                                src={reportTiktokData.image[0].localFile.publicURL}
                                alt="Pogląd Badania"
                            />
                        </div>
                    )}
                    <span>{reportTiktokData.image_description}</span>
                </div>
            </div>

            <InfoTiles data={reportTiktokData.info} />
            <div ref={emailRef} className="email-section">
                <h4>
                    {reportTiktokData.email_description}
                </h4>
                <h3>
                    {reportTiktokData.contact_email}
                </h3>
            </div>
            <Footer />
            <ScrollTop />
        </main>
    );
};

const query = graphql`
                query RaportTikTokSettings {
                allTiktokReportPage {
                nodes {
                first_hero_paragraph
                email_description
                header
                second_hero_paragraph
                subheader
                contact_email
                info { 
                description
                header
                icon {
                localFile {
                publicURL
            }
            }
            }
                image {
                localFile {
                publicURL
            }
            }
                image_description
            }
            }
                allGetHeroMainSettings {
                nodes {
                menu {
                name
                main
                additional
                link
            }
                logo {
                localFile {
                publicURL
            }
            }
                small_logo {
                localFile {
                publicURL
            }
            }
            }
            }
            }
                `;

export default RaportTikTokPage;
